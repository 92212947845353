import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import logoimg from '../assets/logo.png';

function Header() {
  return (
    <>
      <nav class="navbar navbar-expand-lg bg-light p-3">
        <div class="container-fluid">
          <div className='col-8'>
            <a className="navbar-brand" href="/"><img src={logoimg} className='logostyle' alt="Logo"></img></a>
          </div>
          <div className='col-4 d-flex justify-content-end respmobile1'>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse ms-auto respmobile" id="navbarNav">
              <ul class="navbar-nav" >
                <li className="nav-item">
                  <a className="nav-link text-dark fw-bold active" aria-current="page" href="/">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-dark fw-bold" href="/AboutUS">About Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-dark fw-bold" href="/Services">Services</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-dark fw-bold" href="/ContactUS">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;







