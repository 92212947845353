import React, { useEffect, useRef, useState } from 'react';
import './counter.css';
import { IoIosPeople } from "react-icons/io";
import { FaHandHoldingMedical } from "react-icons/fa";
import { MdOutlineWork } from "react-icons/md";
import { GiTrophiesShelf } from "react-icons/gi";

const Counter = () => {
    const valueDisplays = useRef([]);
    const [timers, setTimers] = useState([]);
    let interval = 500;
    let maxCount = [5000, 3000, 20, 1];
    let title = ["Happy Patients", "Orthopedic surgeries", "Experience", "Awards"];
    const icons = [IoIosPeople, FaHandHoldingMedical , MdOutlineWork, GiTrophiesShelf];

    useEffect(() => {
        const newTimers = [];

        maxCount.forEach((count, index) => {
            let startValue = 0;
            let endValue = count;
            let duration = Math.floor(interval / endValue);

            let timer = setInterval(() => {
                startValue += 1;
                valueDisplays.current[index].textContent = startValue;

                if (startValue === endValue) {
                    clearInterval(timer);
                }
            }, duration);

            newTimers.push(timer);
        });

        setTimers(newTimers);

        return () => {
            newTimers.forEach((timer) => {
                clearInterval(timer);
            });
        };
    }, []);

    return (
        <div>
            <section id="counter" className="sec-padding">
                <div className="container">
                    <div className="row">
                        {maxCount.map((count, index) => {
                            const Icon = icons[index];
                            return (
                                <div className="col-md-3" key={index}>
                                    <div className="count p-2">
                                        <span className="icon iconfont">
                                            <Icon />
                                        </span>
                                        <p className="number" ref={(ref) => (valueDisplays.current[index] = ref)}>
                                            {count}
                                        </p>
                                        <h4>{title[index]}</h4>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Counter;
