import BannerImg from "../assets/banner.jpg";
import { Carousel } from 'react-bootstrap'
import la from '../assets/slide_dr.png'
import chicago from '../assets/slide2_dr.png'
import ny from '../assets/slide3_dr.png'

export default function Banner() {
  return (
    <Carousel controls={false} indicators={false}>
      <Carousel.Item interval={1000}>
        <img src={la} alt="" className="position-realtive object-fit-fill w-100"></img>
        <div className="position-absolute text-light bannerContent col-5 sliderposition">
          <h4>Best Orthopedic Doctor in Hyderabad for Joint Replacement & Arthroscopy</h4>
          <button className="btn btn-default btn-light conbtn"><a href="/ContactUS" className="text-decoration-none" style={{ color: '#3e93c1' }}>CONTACT US</a></button>
        </div>


      </Carousel.Item>
      <Carousel.Item interval={1000}>
        <img src={chicago} alt="" className="position-realtive object-fit-fill w-100"></img>
        <div className="position-absolute text-light bannerContent col-5 sliderposition">
          <h4>Best Orthopedic Doctor in Hyderabad for Joint Replacement & Arthroscopy</h4>
          <button className="btn btn-default btn-light conbtn"><a href="/AboutUS" className="text-decoration-none" style={{color: '#3e93c1' }}>About US</a></button>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={1000}>
        <img src={ny} alt="" className="position-realtive object-fit-fill w-100"></img>
        <div className="position-absolute text-light bannerContent col-5 sliderposition">
          <h4>Best Orthopedic Doctor in Hyderabad for Joint Replacement & Arthroscopy</h4>
          <button className="btn btn-default btn-light  conbtn"><a href="/AboutUS" className="text-decoration-none" style={{color: '#3e93c1' }}>About US</a></button>
        </div>
      </Carousel.Item>

    </Carousel>

  );
}