import docimg1 from '../assets/ajay_kumar_main_img_dr.png'
import Speclogo from '../assets/Speciallogo.png'
import memlogo from '../assets/memlogo.png'
import explogo from '../assets/quality.png'
export default function CardSection() {
    return (
        <div className='container-fluid p-0'>
            {/* <div className='container'>
                <div className='row'>
                    <div className='col-md-12 pt-5'>
                        <div className='row'>
                        <div className='col-md-5'>
                                <div className='row'>
                                    <img src={docimg1} alt='' className="ajaypimg"></img>
                                </div>
                            </div>
                            <div className='col-md-7 pt-5'>
                                <div className='p-3 row'>
                                    <h2>Dr Ajay Kumar Parchuri</h2>
                                    <h3>MBBS, MS and M.CH</h3>
                                    <div><p><b className='maincolor'>BEST ORTHOPEDIC DOCTOR IN HYDERABAD </b>
                                        <small>WITH MORE THAN 18 YEARS OF EXPERIENCE IN CONSULTANT ORTHOPAEDICS, ARTHROSCOPY, TRAUMA & JOINT REPLACEMENT SURGEON</small></p></div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12 pb-5'>
                                        <div className='row'>
                                            <div className='col-md-6 pt-1'>
                                                <div className='row'>
                                                    <div className="card border-0">
                                                        <div className="card-body lh-base">
                                                            <img src={Speclogo} alt='' className='mb-2 w-25' />
                                                            
                                                            <div className=''>
                                                                <h4 className="card-title maincolor">Specializations</h4>
                                                                <div className='redbot'> </div>
                                                                <ul className='p-0'>
                                                                    <li>Spine Surgeon (Ortho)</li>
                                                                    <li>Spine And Pain Specialist</li>
                                                                    <li>Orthopedic surgeon</li>
                                                                    <li>Joint Replacement Surgeon</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 pt-1'>
                                                <div className='row'>
                                                    <div className="card border-0">
                                                        <div className="card-body lh-base ">
                                                            <img src={memlogo} alt='' className='mb-2 w-25' />
                                                            <h4 className="card-title maincolor">Memberships</h4>
                                                            <div className='redbot'> </div>
                                                            <ul className='p-0'>
                                                                <li>Indian Orthopaedic Association</li>
                                                                <li>Indian Medical Association</li>
                                                                <li>Indian Arthroscopy Society</li>
                                                                <li>&nbsp;</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 pt-5'>
                        <div className='row'>
                            <div className='col-md-5'>
                                <div className='row'>
                                    <img src={docimg1} alt='' className="ajaypimg img-fluid"></img>
                                </div>
                            </div>
                            <div className='col-md-7 pt-5'>
                                <div className='p-3 row'>
                                    <h2>Dr Ajay Kumar Parchuri</h2>
                                    <h3>MBBS, MS and M.CH</h3>
                                    <div>
                                        <p>
                                            <b className='maincolor'>BEST ORTHOPEDIC DOCTOR IN HYDERABAD </b>
                                            <small>WITH MORE THAN 18 YEARS OF EXPERIENCE IN CONSULTANT ORTHOPAEDICS, ARTHROSCOPY, TRAUMA & JOINT REPLACEMENT SURGEON</small>
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12 pb-5'>
                                        <div className='row'>
                                            <div className='col-md-6 pt-1'>
                                                <div className='row'>
                                                    <div className="card border-0">
                                                        <div className="card-body lh-base">
                                                            <img src={Speclogo} alt='' className='mb-2 w-25' />
                                                            <div className=''>
                                                                <h4 className="card-title maincolor">Specializations</h4>
                                                                <div className='redbot'> </div>
                                                                <ul className='p-0'>
                                                                    <li>Spine Surgeon (Ortho)</li>
                                                                    <li>Spine And Pain Specialist</li>
                                                                    <li>Orthopedic surgeon</li>
                                                                    <li>Joint Replacement Surgeon</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 pt-1'>
                                                <div className='row'>
                                                    <div className="card border-0">
                                                        <div className="card-body lh-base ">
                                                            <img src={memlogo} alt='' className='mb-2 w-25' />
                                                            <h4 className="card-title maincolor">Memberships</h4>
                                                            <div className='redbot'> </div>
                                                            <ul className='p-0'>
                                                                <li>Indian Orthopaedic Association</li>
                                                                <li>Indian Medical Association</li>
                                                                <li>Indian Arthroscopy Society</li>
                                                                <li>&nbsp;</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid greybg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-9'>
                            <div className='row exp m-3'>
                                <h4 className='maincolor'>Experience:</h4>
                                <div className='redbot'> </div>
                                <ul className='stylelist'>
                                    <li className='p-1'>Consultant Orthopaedic & Arthroscopic Surgeon at Apollo Hospitals, Hyderabad</li>
                                    <li className='p-1'>Junior Consultant at Apollo Hospitals, Hyderabad</li>
                                    <li className='p-1'>Senior Registrar at Nizam's Institute of Medical Sciences (NIMS), Hyderabad</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-3 mt-5'>
                            <div className='row expimg'>
                                <img src={explogo} alt='' className='expimg1 expimg' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}