import React from 'react';
import LigamentReconstructionImg from '../assets/ligamentreconstructiondr.png';

const LigamentReconstruction = () => {
    return (
        <div className='text-md-justify lh-lg mainmar'>
            <div className='container-fluid p-0 mainmar'>
                <div className="col-md-12">
                    <img src={LigamentReconstructionImg} alt="Ligament Reconstruction" className='w-100'/>
                </div>
            </div>

            <div className='container mainclass'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row'>
                            <h4>Definition:-</h4><hr />
                            <p>Ligament reconstruction is a surgical procedure that involves repairing or replacing a damaged ligament to restore joint stability. Let's explore the description, symptoms, causes, diagnosis, and treatment associated with ligament reconstruction.</p>
                            <h6>Description:</h6>
                            <p>Ligament reconstruction may be performed to address torn or injured ligaments within a joint. The procedure typically involves the use of grafts to replace damaged ligaments and restore normal joint function.</p>
                            <h4>Symptoms:-</h4><hr />
                            <p>Symptoms Indicating the Need for Ligament Reconstruction:</p>
                            <ol className='padleft'>
                                <li><strong>Persistent Joint Instability:</strong> Feeling of joint instability or giving way.</li>
                                <li><strong>Chronic Pain:</strong> Persistent pain in the joint, especially during movement.</li>
                                <li><strong>Limited Range of Motion:</strong> Difficulty moving the joint due to ligament damage.</li>
                                <li><strong>Swelling and Inflammation:</strong> Recurrent or persistent swelling in the joint.</li>
                                <li><strong>Functional Impairment:</strong> Inability to perform regular activities due to ligament issues.</li>
                            </ol>
                            <h4>Causes</h4><hr />
                            <p>Common Causes Requiring Ligament Reconstruction:</p>
                            <ol className='padleft'>
                                <li><strong>Sports Injuries:</strong> Ligament injuries often occur in sports activities, such as ACL tears in soccer or basketball.</li>
                                <li><strong>Traumatic Injuries:</strong> Accidents or trauma leading to ligament damage.</li>
                                <li><strong>Chronic Ligament Instability:</strong> Long-term ligament laxity or repeated subluxations.</li>
                            </ol>
                            <h4>Diagnosis:</h4><hr />
                            <p>Diagnostic Process for Ligament Reconstruction:</p>
                            <ol className='padleft'>
                                <li><strong>Medical History and Physical Examination: </strong>The physician evaluates the patient's medical history and conducts a physical examination to assess joint symptoms and function.</li>
                                <li><strong>Imaging Studies: </strong>X-rays, MRI, or CT scans may be ordered to visualize the joint's internal structures and identify any abnormalities.</li>
                                <li><strong>Functional Testing:</strong> Assessment of joint stability and function through specific tests.</li>
                            </ol>
                            <h4 >Treatment:</h4><hr />
                            <p>Treatment Modalities in Ligament Reconstruction:</p>
                            <ul className='padleft'>
                                <ol>
                                    <li><strong>Ligament Repair:</strong> Surgical reattachment of torn ligaments.</li>
                                    <li><strong>Ligament Reconstruction:</strong> Replacement of damaged ligaments with grafts (autografts or allografts).</li>
                                    <li><strong>Postoperative Rehabilitation:</strong> Physical therapy to restore strength, range of motion, and joint function.</li>
                                </ol><br />
                            </ul>
                            <h4>Risks and Complications:</h4><hr />
                            <p>Potential Risks of Ligament Reconstruction:</p>
                            <ul className='padleft'>
                                <ol>
                                    <li><strong>Infection:</strong> Risk of infection at the surgical site.</li>
                                    <li><strong>Bleeding:</strong> Bleeding during or after the surgery.</li>
                                    <li><strong>Graft Failure:</strong> Rare instances of graft failure or rejection.</li>
                                    <li><strong>Joint Stiffness:</strong> Temporary stiffness or limited range of motion.</li>
                                </ol><br />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LigamentReconstruction;
