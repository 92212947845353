import React from 'react'
import fracimg from '../assets/fracture_dr.png'
import frozimg from '../assets/frozen_dr.png'
import shouldimg from '../assets/shoulder_dr.png'
import kneeimg from '../assets/knee_dr.png'
import rotimg from '../assets/rotimg_dr.png'
import spineimg from '../assets/disc_dr.png'
import hipndkneeimg from '../assets/hipndknee_dr.png'
const Servicescard = () => {
    return (
        <div className='container p-5'>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='row'>
                        <div className='text-center p-3'>
                            <h3 className='maincolor'>Featured Services</h3>
                            <div className='redbot1'> </div>
                        </div>
                        <div className='col-md-3 p-2'>
                            <div className='row p-1'>
                                <a href="/Services/Fracture" className='text-decoration-none'>
                                    <div className="card p-0 featurecard">
                                        <img className="card-img-top object-fit-fill" src={fracimg} alt="Card image cap" />
                                        <div className="card-body p-3">
                                            <h6 className=" maincolor card-title fw-bold">Fracture Treatment</h6>
                                            <p className="card-text">Treatment includes immobilising the bone with a plaster cast, or surgically inserting metal rods or plates to hold the bone pieces together.</p>
                                            <button className="btn btn-sm text-white">View more</button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-md-3 p-2'>
                            <div className='row p-1'>
                                <a href="/Services/Frozenshoulder" className='text-decoration-none'>
                                    <div className="card p-0 featurecard">
                                        <img className="card-img-top object-fit-fill" src={frozimg} alt="Card image cap" />
                                        <div className="card-body p-3">
                                            <h6 className="card-title maincolor fw-bold">Frozen Shoulder</h6>
                                            <p className="card-text">Frozen shoulder includes physical therapy, pain relief medications, and, in severe cases, surgical interventions to improve joint movement.</p>
                                            <button className="btn btn-sm text-white">View more</button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-md-3 p-2'>
                            <div className='row p-1'>
                                <a href="/Services/ShoulderArthroscopy" className='text-decoration-none'>
                                    <div className="card p-0 featurecard">
                                        <img className="card-img-top object-fit-fill" src={shouldimg} alt="Card image cap" />
                                        <div className="card-body p-3">
                                            <h6 className="card-title maincolor fw-bold">Shoulder Arthroscopy</h6>
                                            <p className="card-text">It is a minimally invasive surgical procedure to diagnose and treat shoulder joint issues using a specialized camera and instruments.</p>
                                            <button className="btn btn-sm text-white">View more</button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-md-3 p-2'>
                            <div className='row p-1'>
                                <a href="/Services/KneeArthroscopy" className='text-decoration-none'>
                                    <div className="card p-0 featurecard">
                                        <img className="card-img-top object-fit-fill" src={kneeimg} alt="Card image cap" />
                                        <div className="card-body p-3">
                                            <h6 className="card-title maincolor fw-bold">Knee arthroscopy</h6>
                                            <p className="card-text">It is a minimally invasive surgical procedure involving the use of an arthroscope to diagnose and treat joint issues through small incisions.</p>
                                            <button className="btn btn-sm text-white">View more</button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='row p-1'>
                                <a href="/Services/RotatorCuffInjury" className='text-decoration-none'>
                                    <div className="card p-0 featurecard">
                                        <img className="card-img-top object-fit-fill" src={rotimg} alt="Card image cap" />
                                        <div className="card-body p-3">
                                            <h6 className="card-title maincolor fw-bold">Rotator Cuff Injury</h6>
                                            <p className="card-text">It involves a combination of rest, physical therapy, anti-inflammatory medications, and in some cases surgical intervention to repair the damaged tendon.</p>
                                            <button className="btn btn-sm text-white">View more</button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='row p-1'>
                                <a href="/Services/SpinalDiscSurgery" className='text-decoration-none'>
                                    <div className="card p-0 featurecard">
                                        <img className="card-img-top object-fit-fill" src={spineimg} alt="Card image cap" />
                                        <div className="card-body p-3">
                                            <h6 className="card-title fw-bold maincolor">Spinal Disc Surgery</h6>
                                            <p className="card-text">It is surgery to remove the damaged part of a disk in the spine that has its soft center pushing out through the tough outer lining. A herniated disk can irritate or press on nearby nerves.</p>
                                            <button className="btn btn-sm text-white">View more</button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='row p-1'>
                            <a href="/Services/RevisionArthroplasty" className='text-decoration-none'>
                                <div className="card p-0 featurecard">
                                    <img className="card-img-top object-fit-fill" src={hipndkneeimg} alt="Card image cap" />
                                    <div className="card-body p-3">
                                        <h6 className="card-title maincolor fw-bold">Hip & Knee Arthroplasty</h6>
                                        <p className="card-text">It is a longer, more complex procedure. It requires extensive planning, as well as the use of specialized implants and tools, in order to achieve a good result.</p>
                                        <button className="btn btn-sm text-white">View more</button>

                                    </div>
                                </div>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Servicescard