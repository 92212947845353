import React from 'react';
import BankartRepairImg from '../assets/bankartrepairdr.png';

const BankartRepair = () => {
  return (
    <div className='text-md-justify lh-lg mainmar'>
            <div className='container-fluid p-0 mainmar'>
        <div className='col-md-12'>
          <img src={BankartRepairImg} alt='Bankart Repair Image' className='w-100' />
        </div>
      </div>

      <div className='container mainclass'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='row'>
              <h4>Definition:-</h4>
              <hr />
              <p>
                Bankart Repair is a surgical procedure that addresses shoulder instability by repairing a tear in the labrum, a piece of cartilage attached to the shoulder socket. Let's explore the description, symptoms, causes, diagnosis, and treatment associated with Bankart Repair.
              </p>
              <h6>Description:</h6>
              <p>
                Bankart Repair involves the surgical reattachment of the torn labrum to the rim of the shoulder socket. This procedure is commonly performed arthroscopically, allowing for minimally invasive surgery.
              </p>
              <h4>Symptoms:</h4>
              <hr />
              <p>
                Symptoms Indicating the Need for Bankart Repair:
              </p>
              <ol className='padleft'>
                <li>
                  <strong>Recurrent Shoulder Dislocations:</strong> Multiple instances of the shoulder dislocating or feeling unstable.
                </li>
                <li>
                  <strong>Persistent Shoulder Pain:</strong> Chronic pain in the shoulder, especially during certain movements.
                </li>
                <li>
                  <strong>Decreased Range of Motion:</strong> Limited ability to move the shoulder through its full range of motion.
                </li>
              </ol>
              <h4>Causes:</h4>
              <hr />
              <p>
                Common Causes Requiring Bankart Repair:
              </p>
              <ol className='padleft'>
                <li>
                  <strong>Shoulder Dislocation:</strong> A previous shoulder dislocation can lead to a Bankart lesion.
                </li>
                <li>
                  <strong>Trauma:</strong> Injuries, such as falls or collisions, can cause labral tears.
                </li>
                <li>
                  <strong>Overuse:</strong> Repetitive overhead movements or strain on the shoulder joint.
                </li>
              </ol>
              <h4>Diagnosis:</h4>
              <hr />
              <p>
                Diagnostic Process for Bankart Repair:
              </p>
              <ol className='padleft'>
                <li>
                  <strong>Medical History and Physical Examination:</strong> The physician evaluates the patient's medical history and conducts a physical examination to assess shoulder symptoms and function.
                </li>
                <li>
                  <strong>Imaging Studies:</strong> MRI or CT scans may be ordered to visualize the shoulder joint and identify labral tears.
                </li>
              </ol>
              <h4>Treatment:</h4>
              <hr />
              <p>
                Treatment Modalities in Bankart Repair:
              </p>
              <ul className='padleft'>
                <ol>
                  <li>
                    <strong>Arthroscopic Bankart Repair:</strong> The torn labrum is reattached to the shoulder socket using arthroscopic techniques.
                  </li>
                  <li>
                    <strong>Rehabilitation Exercises:</strong> Post-surgery, physical therapy is essential to regain shoulder strength and stability.
                  </li>
                </ol>
              </ul>
              <h4>Recovery and Rehabilitation:</h4>
              <hr />
              <p>
                Recovery Following Bankart Repair:
              </p>
              <ul className='padleft'>
                <ol>
                  <li>
                    <strong>Postoperative Care:</strong> Following the surgery, adherence to postoperative care instructions is crucial, including rest and physical therapy.
                  </li>
                  <li>
                    <strong>Gradual Return to Activities:</strong> The timeline for returning to regular activities will be guided by the surgeon based on individual healing.
                  </li>
                </ol>
              </ul>
              <h4>Risks and Complications:</h4>
              <hr />
              <p>
                Potential Risks of Bankart Repair:
              </p>
              <ul className='padleft'>
                <ol>
                  <li>
                    <strong>Infection:</strong> Risk of infection at the surgical site.
                  </li>
                  <li>
                    <strong>Bleeding:</strong> Bleeding within the joint or at the incision sites.
                  </li>
                  <li>
                    <strong>Nerve or Vascular Injury:</strong> Rare instances of damage to nerves or blood vessels near the shoulder.
                  </li>
                </ol>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankartRepair;
