import React from 'react'
import RotatorCuffImg from '../assets/rotator_cuff_injury_treatment_dr.png';

const RotatorCuffInjury = () => {
    return (
        <div className='text-md-justify lh-lg mainmar'>
            <div className='container-fluid p-0 mainmar'>
                <div className="col-md-12">
                    <img src={RotatorCuffImg} alt="Rotator Cuff Injury" className='w-100'/>
                </div>
            </div>

            <div className='container mainclass'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row'>
                            <h4>Definition:-</h4><hr />
                            <p>Rotator cuff injury is a condition affecting the group of muscles and tendons surrounding the shoulder joint. Let's explore the symptoms, causes, diagnosis, and treatment associated with rotator cuff injuries.</p>

                            <h4>Symptoms:-</h4><hr />
                            <p>Common Symptoms of Rotator Cuff Injury:</p>
                            <ul className='padleft'>
                                <li><strong>Shoulder Pain:</strong> Persistent pain in the shoulder, especially during specific movements.</li>
                                <li><strong>Weakened Arm:</strong> Weakness in the affected arm, making certain activities challenging.</li>
                                <li><strong>Difficulty Sleeping:</strong> Discomfort or pain that interferes with sleep, particularly when lying on the affected side.</li>
                                <li><strong>Reduced Range of Motion:</strong> Limited ability to move the shoulder through its full range of motion.</li>
                                <li><strong>Crackling Sensation:</strong> A crackling or popping sensation during certain shoulder movements.</li>
                            </ul>

                            <h4>Causes:</h4><hr />
                            <p>Common Causes of Rotator Cuff Injuries:</p>
                            <ul className='padleft'>
                                <li><strong>Overuse or Repetitive Stress:</strong> Repeated overhead arm movements or activities that strain the shoulder.</li>
                                <li><strong>Age:</strong> Degeneration of the tendons with age, making them more susceptible to injury.</li>
                                <li><strong>Trauma:</strong> Acute injuries, such as falls or impacts, leading to rotator cuff tears.</li>
                                <li><strong>Impingement:</strong> Compression of the rotator cuff tendons in the shoulder joint.</li>
                            </ul>

                            <h4>Diagnosis:</h4><hr />
                            <p>Diagnosis of Rotator Cuff Injuries:</p>
                            <ul className='padleft'>
                                <li><strong>Physical Examination:</strong> Evaluation of shoulder movement, strength, and tenderness.</li>
                                <li><strong>Imaging Tests:</strong> X-rays, MRI, or ultrasound to visualize the shoulder structures and identify tears or inflammation.</li>
                            </ul>

                            <h4>Treatment:</h4><hr />
                            <p>Treatment Approaches for Rotator Cuff Injuries:</p>
                            <ul className='padleft'>
                                <li><strong>Rest and Activity Modification:</strong> Avoiding activities that aggravate symptoms and allowing the shoulder to rest.</li>
                                <li><strong>Physical Therapy:</strong> Specific exercises to strengthen the shoulder muscles and improve range of motion.</li>
                                <li><strong>Medications:</strong> Pain relievers and anti-inflammatory drugs to manage pain and reduce inflammation.</li>
                                <li><strong>Corticosteroid Injections:</strong> Injections to alleviate inflammation and pain in the shoulder joint.</li>
                                <li><strong>Surgical Intervention:</strong> In cases of severe tears, surgery may be considered for repair.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RotatorCuffInjury;
